export default {
  Auth: {
    identityPoolId: 'us-east-1:eb489068-5765-4cc5-8b66-77dcf2c79254',
    region: 'us-east-1',
    userPoolId: 'us-east-1_1ABRJFJc4',
    userPoolWebClientId: '7g7d1m48h0hnns2at5qhl98a9s',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    aws_appsync_graphqlEndpoint: 'https://jxnyxpujvrhjzayujkygyfepta.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  Storage: {
    AWSS3: {
      bucket: 'lighting-stage-assets-storage',
      region: 'us-east-1',
    },
  },
  oauth: {
    domain: 'auth.stage.gosourcery.com',
  },
};
